import { useCallback, useEffect, useMemo } from "react";
import { useActiveWeb3React } from "./useActiveWeb3React";
import { useNetwork, useSignMessage } from "wagmi";
import { fetcher } from "utils/fetch";
import { useUserAction } from "store/user";
import { useAccessToken, useUserData } from "./useUserData";
import { checkExpiredTime } from "utils/time";
import notification from "antd/lib/notification";
import { socket } from "utils/socket";

export const useSignData = () => {
  const { account: address } = useActiveWeb3React();
  const userData = useUserData();
  const { signIn } = useSignIn();
  const tokens = useAccessToken();
  const isExpiredToken = useMemo(() => {
    if (!tokens?.refresh || !address || !userData) {
      return false;
    }
    return checkExpiredTime(tokens?.refresh?.expires);
  }, [address, tokens?.refresh, userData]);
  useEffect(() => {
    if (
      (address &&
        address?.toLowerCase() !== userData?.address?.toLowerCase()) ||
      isExpiredToken
    ) {
      signIn();
    }
  }, [address, userData?.address, isExpiredToken, socket]);
};

export const useSignIn = () => {
  const { chain: activeChain } = useNetwork();
  const { account: address } = useActiveWeb3React();
  const { signMessageAsync } = useSignMessage();
  const { setUserInfo } = useUserAction();
  const userData = useUserData();

  const signIn = useCallback(async () => {
    try {
      const chainId = activeChain?.id;

      if (!address || !chainId) {
        return false;
      }

      return await signMessageAsync({
        message: `${address}`,
      })
        .then(async (signature) => {
          const { tokens, user } = await fetcher<any>("/auth/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ address, signature, message: address }),
          });
          socket?.emit("leaveRoom", {
            room: userData?.address?.toLowerCase(),
          });
          if (user) {
            setUserInfo({ userInfo: user, tokens });
          }
          return true;
        })
        .finally(() => {
          return null;
        });
    } catch (error: any) {
      if (error.action === "signMessage" && error.code === "ACTION_REJECTED") {
        notification.error({
          message: "You declined the action in your wallet	",
        });
      } else {
        notification.error({ message: "Something went wrong." });
      }
      return false;
    }
  }, [activeChain?.id, address, userData?.address]);
  return { signIn };
};
