import notification from "antd/lib/notification";
import { useSignMessage } from "app-sdk";
import PrimaryButton from "components/common/button";
import { useCallback, useState } from "react";
import { useUserAction } from "store/user";
import { fetcher } from "utils/fetch";
import { useAccount, useNetwork } from "wagmi";

export const SignBtn = ({
  className,
  text = "Verify account",
  icon,
  callBack,
}: {
  className?: string;
  text?: string | null;
  icon?: any;
  callBack?: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();

  const { setUserInfo } = useUserAction();
  const { chain: activeChain } = useNetwork();
  const { signMessageAsync } = useSignMessage();

  const signIn = useCallback(async () => {
    console.log('SignBtn');
    if (loading) return;
    setLoading(true);
    try {
      const chainId = activeChain?.id;
      if (!address || !chainId) {
        notification.error({ message: "Connect your Wallet" });
        return;
      }

      const signature = await signMessageAsync({
        message: `${address}`,
      });
      
      // Verify signature
      const { tokens, userInfo } = await fetcher<any>("/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ address, signature, message: address }),
      });
      setUserInfo({ userInfo, tokens });
      callBack && callBack();
    } catch (error: any) {
      if (error.action === "signMessage" && error.code === "ACTION_REJECTED") {
        notification.error({
          message: "You declined the action in your wallet	",
        });
      } else {
        notification.error({ message: "Something went wrong." });
      }
    } finally {
      setLoading(false);
    }
  }, [
    activeChain?.id,
    address,
    callBack,
    loading,
    setUserInfo,
    signMessageAsync,
  ]);

  return (
    <PrimaryButton
      disabled={loading}
      text="Verify Account"
      className={className}
      onClick={signIn}
    />
  );
};
