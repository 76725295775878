export enum FetchStatus {
  Idle = "IDLE",
  Fetching = "FETCHING",
  Fetched = "FETCHED",
  Failed = "FAILED",
}

export const FAST_INTERVAL = 10000;
export const SLOW_INTERVAL = 60000;

export enum ChainId {
  ETHEREUM = 1,
  GOERLI = 5,
  POLYGON = 137,
  MUMBAI = 80001,
}
