import { formatEther } from "@ethersproject/units";
import { removeHtmlTagFromString } from "./string";
import { IFooterTab, ILinkDetail, SocialItem } from "views/NFTMarket/types";

export function roundDown(number: number, decimals = 0) {
  return checkDecimals(number, decimals)
    ? number
    : Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export const checkDecimals = (num: number, decimals: number) => {
  if (!num) {
    return;
  }
  const numArr = num.toString().split(".");
  const lengthDecimal = numArr[1]?.length || 0;
  return lengthDecimal <= decimals;
};

const escapeRegExp = (string: string): string => {
  const digits = string.split("").filter((char) => /\d/.test(char));
  let result = "";

  for (let i = 0; i < digits.length; i++) {
    result += digits[i];
    if ((i + 1) % 3 === 0 && i !== digits.length - 1) {
      result += ",";
    }
  }

  return result;
};

export const enforcer = (
  nextUserInput: string,
  onUserInput: (input: string) => void
) => {
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // m
  if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
    onUserInput(nextUserInput);
  }
};

export const checkNumberElement = (text: string, value?: number) => {
  return (value || 0) > 1 ? `${value} ${text}s` : `${value} ${text}`;
};

export const convertNumberToEthBalance = (value: number | string) => {
  return (Number(value) * Math.pow(10, 18)).toString();
};

export const renderNetWorkName = (networkId: number | undefined) => {
  switch (networkId) {
    case 1:
      return "Ethereum Mainnet";
    case 5:
      return "Goerli (ETH Testnet)";
    case 80001:
      return "Polygon Mumbai";
    default:
      return "Goerli (ETH Testnet)";
  }
};

export function secondsToHoursMinSec(time: number) {
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
}

export const convertToEther = (value: string) => {
  try {
    return formatEther(value);
  } catch (error) {
    return 0;
  }
};

export const formatTokenResponse = (response: any) => {
  const { title, data } = response?.data;
  return {
    ...(response.data ?? {}),
    url: "https://earthwise.games/token",
    titleSeo: title,
    descriptionSEO: removeHtmlTagFromString(data.html),
  };
};

export const formatFAQResponse = (response: any) => {
  return {
    ...(response.data ?? {}),
    url: "https://earthwise.games/faq",
    titleSeo: "What type of game is Elowyn: Quest of Time?",
    descriptionSEO:
      "It’s a massive multiplayer online game (MMORPG), including matches, tournaments, and open-world immersive quests and side-quests. The game genre is a hybrid between serious game—Playing for the Planet and Games for Change—and games for fun and entertainment, with mythic and science-fiction elements.",
    banner: "/banner.png",
  };
};

export const formatStoryResponse = (response: any) => {
  const description = removeHtmlTagFromString(response.data?.description);
  return {
    ...response.data,
    banner: response?.data?.image,
    url: `https://earthwise.games/stories/${response?.data?.story_id}`,
    titleSeo: response?.data?.title,
    descriptionSEO: description,
  };
};

const getActiveLink = (links: ILinkDetail[]) => {
  return links.find((link) => !!link.is_active);
};

const inactiveSections = ["Terms of Service", "Privacy Policy", "Whitepaper"];

export const formatFooterResponse = (response: IFooterTab[] | undefined) => {
  if (!response || !response.length) {
    return [];
  }
  return response.map((e) => {
    const activeLink = getActiveLink(e.links);
    return {
      title: e.title,
      link: activeLink?.link,
      isExternal: activeLink?.type === "external",
      isSection:
        activeLink?.type == "internal" &&
        !inactiveSections.find((section) => section === e.title),
    };
  });
};

export const formatSocialResponse = (socials: SocialItem[] | undefined) => {
  if (!socials || !socials.length) return [];
  return socials.map((e) => {
    return {
      key: e.name,
      url: e.image,
      link: e.url,
    };
  });
};

export const formatNftDetailResponse = (response: any) => {  
  return {
    ...response.data,
    banner: response?.data?.image,
    url: `https://earthwise.games/stories/${response?.data?.nftId}`,
    titleSeo: response?.data?.name,
    descriptionSEO: response.data.description,
  };
};