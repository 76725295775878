import { ChainId } from "app-sdk/constants";

export enum Currency {
  ETH = 'ETH',
  WETH = 'WETH',
}

export const TokenPayment: { [chain in ChainId]: { [token: string]: string } } = {
  [ChainId.ETHEREUM]: {
    USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  },
  [ChainId.GOERLI]: {
    USDT: "0xA46B8897c2b657ffedbAb2A013F33ba6BF9c1F0F",
    USDC: "0xd87ba7a50b2e7e660f678a895e4b72e7cb4ccd9c",
  },
  [ChainId.POLYGON]: {
    USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    USDC: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  },
  [ChainId.MUMBAI]: {
    USDT: "0xA5226E024de8c6E7712e2cbCbF149F8eeB55aa4F",
    USDC: "0xD426B8AfF5378C80e4614f9aabcB486edA70E989",
  },
};

