import { useEffect } from "react";
import store from "store";
import { useUserAction, useUserSelector } from "store/user";
import { useAccount, useClient, useConnect } from "wagmi";
import { useAtom } from "jotai";
import { globalData } from "./useGlobalData";

const SAFE_ID = "safe";
store?.getState();

const useEagerConnect = () => {
  const client = useClient();
  const { connectAsync, connectors } = useConnect();
  const { userInfo } = useUserSelector();
  const { address } = useAccount();
  const { resetUser } = useUserAction();
  const [data, setGlobalData] = useAtom(globalData);
  useEffect(() => {
    if (address && userInfo && address.toLowerCase() !== userInfo.address) {
      resetUser();
    }
  }, [address, userInfo, resetUser]);

  useEffect(() => {
    const connected = Boolean(localStorage.getItem("wagmi.connected"));
    if (!connected) return;
    const connectorName = localStorage.getItem("wagmi.wallet");
    setGlobalData({ ...data, connectorName:connectorName?.replaceAll('"','')});

    const connectorInstance = connectors.find(
      (c) => c.id === SAFE_ID && c.ready
    );
    if (
      connectorInstance &&
      // @ts-ignore
      !window.cy
    ) {
      connectAsync({ connector: connectorInstance }).catch(() => {
        client.autoConnect();
      });
    } else {
      client.autoConnect();
    }
  }, [client, connectAsync, connectors]);
};

export default useEagerConnect;
