import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import useSWR from 'swr';

import { Zero } from '@ethersproject/constants';

import { FAST_INTERVAL } from '../constants';
import { BIG_ZERO } from '../utils';
import { useTokenContract } from './useContract';
import { useSWRContract } from './useSWRContract';
import { useWeb3React } from './useWeb3React';
import { TokenPayment } from 'types/enums/currency';
import { useProvider } from 'wagmi';

export const useTokenBalance = (tokenAddress: string, forceBSC?: boolean) => {
  const { account = '', chainId } = useWeb3React();
  const provider = useProvider({ chainId });
  const contract = useTokenContract(tokenAddress, false);

  const key = useMemo(
    () =>
      account
        ? {
            contract: forceBSC ? contract?.connect(provider) : contract,
            methodName: 'balanceOf',
            params: [account],
          }
        : null,
    [account, contract, forceBSC, provider]
  );

  const { data, status, ...rest } = useSWRContract(key as any, {
    refreshInterval: FAST_INTERVAL,
  });

  return {
    ...rest,
    fetchStatus: status,
    balance: data ? new BigNumber(data.toString()) : BIG_ZERO,
  };
};

export const useNativeBalance = () => {
  const { account = '', chainId } = useWeb3React();
  const provider = useProvider({ chainId });
  const { status, data, mutate } = useSWR([account, 'nativeTokenBalance'], async () => {
    return provider.getBalance(account);
  });

  return { balance: (data || Zero).toString(), fetchStatus: status, refresh: mutate };
};

export const useBusdBalance = () => {
  const { chainId } = useWeb3React();
  const token: { [ky: number]: string } = {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
  };
  return useTokenBalance(token[chainId || 56]);
};

export const useEthBalance = () => {
  const { account = '', chainId } = useWeb3React();
  const provider = useProvider({ chainId });
  const { status, data, mutate } = useSWR([account, 'ethBalance',chainId], async () => {
    return provider.getBalance(account);
  });


  return { balance: (data || Zero).toString(), fetchStatus: status, refresh: mutate };
};

export const useUSDCBalance = () => {
  const { chainId }  = useWeb3React();
  const token=(TokenPayment as any)[chainId as number]['USDC'];
  return useTokenBalance(token);
};
