import { ChainId } from "app-sdk";
import { ERROR_MESSAGE } from "config/constants/message";
import { format, parseISO } from "date-fns";

export const validateEmail = (email: string): string => {
  const REGEX_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  if (!email) return "Please enter your email address";
  if (!REGEX_EMAIL.test(email)) return "Please enter a valid email address";
  return "";
};

export const formatTime = (date: string): string => {
  if (!date) {
    return "--";
  }
  const parsedDate = parseISO(date);
  return format(parsedDate, "MMMM do',' yyyy");
};

export const getTruncateHash = (hash: string, length: number = 6) => {
  if (!hash) {
    return "";
  }
  if (hash.length < 20) {
    return hash;
  }
  return `${hash.slice(0, length)}...${hash.slice(-length)}`;
};

export const explorerPerChain = (chainId: number) => {
  switch (chainId) {
    case ChainId.GOERLI: {
      return "https://goerli.etherscan.io";
    }

    case ChainId.POLYGON: {
      return "https://polygonscan.com";
    }

    case ChainId.MUMBAI: {
      return "https://mumbai.polygonscan.com";
    }
    default: {
      return "https://etherscan.io";
    }
  }
};

export const getExplorerLink = (
  chainId: number,
  type: "address" | "tx",
  tx: string
) => {
  const baseURl = explorerPerChain(chainId);
  if (type === "address") {
    return `${baseURl}/address/${tx}`;
  }
  return `${baseURl}/tx/${tx}`;
};

export const getVideoIdFromEmbedLink = (link: string): string => {
  const videoIdRegex = /\/embed\/([^?]+)/;
  const match = RegExp(videoIdRegex).exec(link);

  const videoId = match ? match[1] : "";
  return videoId;
};

export const formatNumber = (value: any, precision = 3) => {
  if (!value) {
    return "n/a";
  }

  const formattedValue = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(value);

  return `$${formattedValue}`;
};

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
export const validExternalLink = (rule: any, value: string) => {
  if (value && !isValidUrl(value)) {
    return Promise.reject(new Error("Please enter a valid external link."));
  }

  return Promise.resolve();
};

export const getErrorMessage = (code: string) => {
  return (ERROR_MESSAGE as any)[code] ?? ERROR_MESSAGE.DEFAULT;
};

export const validateDecimals = (value: string, decimals?: number) => {
  const splitData = value.split(".");
  return `${splitData[0]}.${splitData[1].slice(0, decimals)}`;
};

export const truncateWithEllipsis = (text: string, maxLength = 26) => {
  if (!text) {
    return "N/A";
  }
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const convertTitleStory = (title: string) => {
  if (!title) {
    return "";
  }
  return title
    .split(" ")
    .join("-")
    ?.toLowerCase()
    ?.replace(/[^\w\s-]/gi, "");
};

export const removeHtmlTagFromString = (
  htmlString: string,
  maxLength = 155
): string => {
  // Replace HTML tags with an empty string
  const content = htmlString.replace(/<\/?[^\s>]+(?:\s[^>]*?)?\/?>/g, "");
  return truncateWithEllipsis(content, maxLength);
};
