import notification from "antd/lib/notification";
import {
  ConnectorNames,
  replaceBrowserHistory,
  useActiveChainId,
  useSessionChainId,
} from "app-sdk";
import { magic } from "app-sdk/utils/magic";
import { useCallback } from "react";
import { connectorLocalStorageKey } from "config";
import { useUserAction } from "store/user";
import {
  ConnectorNotFoundError,
  UserRejectedRequestError,
  useConnect,
  useDisconnect,
} from "wagmi";

const useAuth = () => {
  const { connectAsync, connectors } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { chainId } = useActiveChainId();
  const [, setSessionChainId] = useSessionChainId();
  const { resetUser } = useUserAction();

  const login = useCallback(
    async (connectorID: ConnectorNames) => {
      const findConnector = connectors.find((c) => c.id === connectorID);
      try {
        const connected = await connectAsync({
          connector: findConnector,
          chainId,
        });
        if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory("chainId", connected.chain.id);
          setSessionChainId(connected.chain.id);
        }
        notification.success({ message: "Connected to wallet sucessfully!" });
      } catch (error) {
        window?.localStorage?.removeItem(connectorLocalStorageKey);
        notification.error({
          message: "Connecting to a wallet failed. Please try again",
        });
        if (error instanceof ConnectorNotFoundError) {
          return;
        }
        if (error instanceof UserRejectedRequestError) {
          return;
        }
        if (error instanceof Error) {
          console.error(
            error.message,
            "Please authorize to access your account"
          );
        }
      }
    },
    [connectors, connectAsync, chainId, setSessionChainId]
  );

  const logout = useCallback(async () => {
    try {
      resetUser();
      await disconnectAsync();
      await magic?.user.logout();
    } catch (error) {
      console.error(error);
    }
  }, [disconnectAsync, resetUser]);

  return { login, logout };
};

export default useAuth;
