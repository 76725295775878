import Modal from 'antd/lib/modal/Modal';
import Image from 'next/image';
import { useSelectModal, useChangeModal } from 'store/modal';
import { isDesktop, isMobile } from 'react-device-detect';

export default function CheckBrowserModal() {
  const { checkBrowserModal } = useSelectModal();
  const { toggleCheckBrowserModal } = useChangeModal();

  const nextLink = () => {
    if (isDesktop) {
      window.open('https://metamask.io/');
      return;
    }
    if (isMobile) {
      const dappUrl = window.location.href.split('//')[1].split('/')[0];
      const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
      window.open(metamaskAppDeepLink, '_self');
      return;
    }
  };

  return (
    <Modal
      open={checkBrowserModal}
      closable={true}
      className="custom-modal"
      footer={null}
      width={312}
      onCancel={() => toggleCheckBrowserModal(false)}
      closeIcon={<Image src="/icons/close-icon.svg" alt="" width={12} height={12} />}
    >
      <div className="pt-10 pb-6 px-7">
        <div className="text-lg pb-6 flex items-center justify-center">
          <span className="pr-[10px]">Welcome to</span> <Image src="/Logo.png" alt="" width={100} height={35} />
        </div>
        <div className="text-center text-sm font-normal">
          {isMobile
            ? "Currently we've not supported this browser. Kindly switch to MetaMask browser for the best experience."
            : "MetaMask not found. Don't have MetaMask Wallet?"}
        </div>
        <div className="flex justify-center pt-5" onClick={nextLink}>
          <button className="px-6 py-3 bg-white text-black text-base font-medium rounded-xl">
            {isMobile ? 'MetaMask' : 'Download MetaMask'}
          </button>
        </div>
      </div>
    </Modal>
  );
}
