import { Chain } from 'wagmi';
import {
  avalanche,
  avalancheFuji,
  bsc,
  bscTestnet,
  fantom,
  fantomTestnet,
  goerli,
  mainnet,
  polygon,
  polygonMumbai as polygonMumbai2,
} from 'wagmi/chains';

export { mainnet, avalanche, avalancheFuji, bsc, bscTestnet, fantom, fantomTestnet, goerli, polygon };

export const polygonMumbai: Chain = {
  ...polygonMumbai2,
  rpcUrls: {
    default: {
      http: [
        process.env.NEXT_PUBLIC_RPC_MUMBAI ||
          'https://polygon-testnet.public.blastapi.io',
      ],
    },
    public: {
      http: [
        process.env.NEXT_PUBLIC_RPC_MUMBAI ||
          'https://polygon-testnet.public.blastapi.io',
      ],
    },
  },
};

// export const avalandche: Chain = {
//   id: 43114,
//   name: 'Avalanche C-Chain',
//   network: 'avalanche',
//   rpcUrls: {
//     default: 'https://rpc.ankr.com/avalanche',
//   },
//   nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
//   blockExplorers: {
//     default: {
//       name: 'snowtrace',
//       url: 'https://snowtrace.io/',
//     },
//   },
// };

// export const avalandcheFuji: Chain = {
//   id: 43113,
//   name: 'Avalanche Fuji',
//   network: 'avalanche-fuji',
//   rpcUrls: {
//     default: 'https://rpc.ankr.com/avalanche_fuji',
//   },
//   nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
//   blockExplorers: {
//     default: {
//       name: 'snowtrace',
//       url: 'https://testnet.snowtrace.io/',
//     },
//   },
//   testnet: true,
// };

// export const fantomOpera: Chain = {
//   id: 250,
//   name: 'Fantom Opera',
//   network: 'fantom',
//   nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
//   rpcUrls: {
//     default: 'https://rpc.ftm.tools',
//   },
//   blockExplorers: {
//     default: {
//       name: 'FTMScan',
//       url: 'https://ftmscan.com',
//     },
//   },
// };

// export const fantomTestnet: Chain = {
//   id: 4002,
//   name: 'Fantom Testnet',
//   network: 'fantom-testnet',
//   nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
//   rpcUrls: {
//     default: 'https://rpc.testnet.fantom.network',
//   },
//   blockExplorers: {
//     default: {
//       name: 'FTMScan',
//       url: 'https://testnet.ftmscan.com',
//     },
//   },
//   testnet: true,
// };

// const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' };

// export const bsc: Chain = {
//   id: 56,
//   name: 'BNB Smart Chain',
//   network: 'bsc',
//   rpcUrls: {
//     public: 'https://bsc-dataseed.binance.org',
//     default: 'https://bsc-dataseed.binance.org',
//   },
//   blockExplorers: {
//     default: bscExplorer,
//     etherscan: bscExplorer,
//   },
//   nativeCurrency: {
//     name: 'Binance Chain Native Token',
//     symbol: 'BNB',
//     decimals: 18,
//   },
// };

// export const bscTest: Chain = {
//   id: 97,
//   name: 'BNB Smart Chain Testnet',
//   network: 'bsc-testnet',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'Binance Chain Native Token',
//     symbol: 'tBNB',
//   },
//   rpcUrls: {
//     public: 'https://data-seed-prebsc-2-s3.binance.org:8545/',
//     default: 'https://data-seed-prebsc-2-s3.binance.org:8545/',
//   },
//   blockExplorers: {
//     default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
//   },
//   testnet: true,
// };

// export const ethw: Chain = {
//   id: 10001,
//   name: 'EthereumPoW',
//   network: 'ethw',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'ETHW (Wrapped)',
//     symbol: 'ETHW',
//   },
//   rpcUrls: {
//     public: 'https://mainnet.ethereumpow.org/',
//     default: 'https://mainnet.ethereumpow.org/',
//   },
//   blockExplorers: {
//     default: { name: 'ethwscan', url: 'https://mainnet.ethwscan.com' },
//   },
// };

// export const goerli: Chain = {
//   ...goerli2,
//   rpcUrls: {
//     default: `https://goerli.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
//   },
// };
